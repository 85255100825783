<template>
  <div class="w-full">
      <div class="w-full">
        <Card class="p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button
              class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="disableGoal ? alertError() : createGoal()"
            >
              Create New
            </Button>
          </div>
          <template v-slot:footer>
            <card-footer
              reloadcard
              @searchResult="handleSearch($event)"
              showcalendar
              @reload="reload()"
            />
          </template>
        </Card>
      </div>
      <div class="w-full h-full p-3" />

      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div v-if="viewRegister">
          <Table
            :headers="PerfHeader"
            :items="shortTermGoals"
            aria-label="advance Table"
            class="w-full px-3"
            :has-checkbox="false"
            has-number
            v-if="shortTermGoals.length > 0 || loadingTable"
            :loading="loadingTable"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.periodStart">
                {{ item.data.periodStart }}
              </div>
              <div v-else-if="item.kpaTypes">
                <div class="flex flex-wrap">
                  <div v-for="(kpas, index) in item.data.kpaTypes" :key="index">
                    <Badge
                      :label="kpas.name"
                      variant="primary"
                      background-color="rgba(135, 142, 153, 0.15)"
                      color="#333333"
                      style="
                        margin-right: 12px;
                        font-size: 14px;
                        padding: 12px 10px;
                        height: 35px;
                        border-radius: 5px;
                      "
                    />
                  </div>
                </div>
              </div>
              <span v-else-if="item.status">
                <Badge
                  v-if="item.data.status === 'active'"
                  label="Active"
                  variant="primary"
                  background-color="rgba(19, 181, 106, 0.08)"
                  color="#13B56A"
                  style="padding: 9px; height: 33px; font-weight: 600"
                />
                <Badge
                  v-if="item.data.status === 'draft'"
                  label="Draft"
                  variant="primary"
                  background-color="rgba(135, 142, 153, 0.15)"
                  color="#333333"
                  style="padding: 9px; height: 33px; font-weight: 600"
                />
                <Badge
                  v-if="
                    item.data.status !== 'active' &&
                    item.data.status !== 'draft'
                  "
                  label="Closed"
                  variant="primary"
                  background-color="rgba(135, 142, 153, 0.15)"
                  color="#878E99"
                  style="padding: 9px; height: 33px; font-weight: 600"
                />
              </span>
              <span v-else-if="item.id">
                <Menu left top="-130" class="p-0" margin="40">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 158px; min-height: 40px" class="py-3 px-2">
                    <div
                      class="flex py-1 px-3 more h-8 cursor-pointer"
                      @click="
                        $router.push({
                          name: 'performance Organisationl Current Edit goal',
                          params: { id: item.data.id },
                        })
                      "
                    >
                      <icon
                        icon-name="edit"
                        class-name="text-blueCrayola mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">View & Edit</p>
                    </div>
                    <div
                      class="flex px-3 py-1 more h-8 cursor-pointer"
                      @click="deleteFunction(item.data.id)"
                      v-if="item.data.status === 'draft'"
                    >
                      <icon
                        icon-name="delete"
                        class-name="text-desire mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">Delete</p>
                    </div>
                  </div>
                </Menu>
              </span>
            </template>
          </Table>

          <div v-else>
            <div class="w-full flex flex-col justify-center items-center mt-10">
              <icon icon-name="goals-empty" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                No organizational goals added. Start by adding one using the
                button below.
              </div>
              <div class="w-1/2 text-base text-center mt-6">
                <Button
                  class="text-flame"
                  @click="
                    $router.push({
                      name: 'performance Organisationl Current New goal',
                    })
                  "
                >
                  <div class="flex items-center ml-6">
                    <icon icon-name="icon-plus" size="xsm" class="mr-3" />
                    Create New
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <ErrorComponent />
        </div>
      </div>

      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this organization goal?
            </p>
            <div class="flex">
              <Button class="text-white" @click="deleteGoal"
                :background="appearance.buttonColor || defaultBTNColor"
              >
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorComponent from "@/modules/Admin/error403";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    Table,
    Card,
    CardFooter,
    ErrorComponent,
    Badge,
    Button,
    Menu,
    Modal,
  },
  data() {
    return {
      hasModuleAccess: false,
      PerfHeader: [
        { title: "Financial Year", value: "year" },
        { title: "Description", value: "description" },
        { title: "Period", value: "periodStart" },
      ],
      shortTermGoals: [],
      templateArr: [],
      appraisalsArr: [],
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      loading: true,
      dialogDel: false,
      goalId: "",
      currentYear: "",
      disableGoal: false,
      loadingTable: true,
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      numberOfPage: null,
      viewRegister: false,
    };
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    async createGoal() {
      try {
        await this.$handlePrivilege(
          "organisationalGoals",
          "createCurrentOrganisationalGoals"
        );
        this.$router.push({
          name: "performance Organisationl Current New goal",
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `&${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOrganizationGoal(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `search=${value}`;
      this.loadingTable = true;
      this.getOrganizationGoal(`?${this.searchQuery}`);
    },

    reload() {
      this.getOrganizationGoal("");
    },

    alertError() {
      this.$toasted.error("You currently have an active goal for this year", {
        duration: 5000,
      });
    },

    deleteFunction(id) {
      this.dialogDel = true;
      this.goalId = id;
    },

    deleteGoal() {
      this.$_deleteShortTermGoal(this.goalId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.loading = true;
          this.disableGoal = false;
          this.getOrganizationGoal();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    cycleName(templateId) {
      let name;

      this.templateArr.filter((template) => {
        if (template.id === templateId) {
          name = template.agreement_title;
        }
        return {};
      });

      return name || "";
    },

    async getOrganizationGoal(params) {
      try {
        await this.$handlePrivilege(
          "organisationalGoals",
          "viewCurrentGoalsRegister"
        );
        this.viewRegister = true;
        this.shortTermGoals = [];
        this.loadingTable = true;

        this.$_getOrganizationGoal(params).then((response) => {
          const goalData = response.data.goals;
          this.metaData = response.data.meta;

          if (goalData.length > 0) {
            const arr = [];

            goalData.forEach((goals) => {
              arr.push({
                id: goals.goal.id,
                year: goals.goal.year,
                description: goals.goal.description,
                status: goals.goal.status,
                employees: goals.appliedEmployees.length,
                createdAt: goals.goal.createdAt,
              });

              if (goals.goal.year === this.currentYear) {
                this.disableGoal = true;
              }
              return {};
            });

            arr.push(
              this.appraisalsArr.reduce((r, i) => Object.assign(r, i), {})
            );

            this.shortTermGoals.push(
              arr.reduce((r, c) => Object.assign(r, c), {})
            );
          }

          this.loading = false;
          this.loadingTable = false;
        });
      } catch (error) {
        this.viewRegister = false;
      }
    },

    getTemplate() {
      this.$_getPerfomanceTemplate("").then((response) => {
        const templatesArr = response.data.PerformanceTemplate;

        this.templateArr = templatesArr;
      });
    },

    getAppraisalCycle() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        const apprArray = [];

        appraisal.map((appraisalCycles) => {
          const startDate = `${appraisalCycles.cycle_start_date}`;

          const endDate = `${appraisalCycles.cycle_end_date}`;

          apprArray.push({
            periodStart: `${this.formatDateTime(
              startDate
            )} - ${this.formatDateTime(endDate)}`,
          });

          appraisalCycles.cycles.map((cycle) => {
            this.PerfHeader.push({
              title: cycle.name,
              value: cycle.name,
            });

            apprArray.push({
              [cycle.name]: `${this.formatDateTime(
                cycle.appraisal_starts
              )} - ${this.formatDateTime(cycle.appraisal_ends)}`,
            });

            return {};
          });

          this.appraisalsArr.push(
            apprArray.reduce((r, i) => Object.assign(r, i), {})
          );

          this.PerfHeader.push(
            { title: "Employees", value: "employees" },
            { title: "Status", value: "status" },
            { title: "", value: "id", image: true }
          );

          return {};
        });

        this.getOrganizationGoal("");
      });
    },
  },

  mounted() {
      const today = new Date();
      this.currentYear = today.getFullYear();
      this.getTemplate();
      this.getAppraisalCycle();
  },
};
</script>
