<template>
  <div class="w-full pt-5">
    <div class="flex w-full">
      <h1 class="text-xl text-left font-extrabold pl-4 mr-8">
        Organisational Goals and Objectives
      </h1>
      <Breadcrumb :items="breadcrumbs" />
    </div>

    <div class="w-full p-5 flex mt-1 flex-col">
    <Tabs
      class="mt-6 mx-3"
      :tabs="tabsItems"
      :active-tab="activeTab"
      @currentTab="activeTab = $event"
      :border="true"
    />

    <div style="height:100%" v-if="loadingData">
      <loader size="xxs" />
    </div>
    <div v-else class="w-full flex flex-col">
    <CurrentGoals v-if="activeTab === 'Current Goals'" />
    <LongTermGoals v-if="activeTab === 'Long Term Goals'" />
    </div>

  </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tabs from '@scelloo/cloudenly-ui/src/components/tab';
import Loader from "@/components/Loader";
import Icon from "@/components/Icon";
import RightSideBar from "@/components/RightSideBar";
import Menu from "@/components/Menu";
import CurrentGoals from "./CurrentGoals/index";
import LongTermGoals from "./LongTermGoals/index";

export default {
  name: "Function",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Menu,
    Tabs,
    Breadcrumb,
    CurrentGoals,
    LongTermGoals,
    Loader,
    Icon,
    RightSideBar
  },
  data() {
    return {
      perfArray: [],
      openModal: false,
      kpaSummary: {},
      loadingData: false,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance"
        },
        {
          disabled: false,
          text: "Organisational Goals",
          href: "goals",
          id: "Goals"
        }
      ],
      tabsItems: [
        "Current Goals",
        "Long Term Goals",
      ],
      activeTab: this.$route.query.activeTab || "Current Goals",
    };
  },

  methods: {
    activateTab(tab) {
      this.activeTab = tab;
      this.$router.push({name: 'OrganisationlGoal',
        query: { activeTab: tab }
        }).catch(()=>{});
    }
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
.button:focus {
  outline: none;
}
</style>
