<template>
  <div class="w-full">
      <div class="w-full">
        <Card class="p-5">
          <div class="flex mb-6" style="justify-content: flex-end">
            <Button class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="createGoal()"
            >
              Create New
            </Button>
          </div>
          <template v-slot:footer>
            <card-footer
              reloadcard
              @searchResult="handleSearch($event)"
              showcalendar
              @reload="reload()"
            />
          </template>
        </Card>
      </div>
      <div class="w-full h-full p-3" />

      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div v-if="viewRegister">
          <Table
            :headers="PerfHeader"
            :items="longTermGoals"
            aria-label="advance Table"
            class="w-full px-3"
            :has-checkbox="false"
            has-number
            v-if="longTermGoals.length > 0 || loadingTable"
            :loading="loadingTable"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
          >
            <template v-slot:item="{ item }">
              <div v-if="item.appraisalCycle">
                <Badge
                  :label="item.data.appraisalCycle"
                  variant="primary"
                  background-color="rgba(33, 118, 255, 0.15)"
                  color="#2176FF"
                  style="
                    margin-right: 10px;
                    padding: 10px 10px;
                    height: 28px;
                    border-radius: 5px;
                  "
                />
              </div>
              <div v-else-if="item.kpaTypes">
                <div class="flex flex-wrap">
                  <div v-for="(kpas, index) in item.data.kpaTypes" :key="index">
                    <Badge
                      :label="kpas.kpa_type.name"
                      variant="primary"
                      background-color="rgba(135, 142, 153, 0.15)"
                      color="#333333"
                      style="
                        margin-right: 12px;
                        font-size: 14px;
                        padding: 12px 10px;
                        height: 35px;
                        border-radius: 5px;
                      "
                    />
                  </div>
                </div>
              </div>
              <span v-else-if="item.createdAt">{{
                $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
              }}</span>
              <span v-else-if="item.updatedAt">{{
                $DATEFORMAT(new Date(item.data.updatedAt), "MMMM dd, yyyy")
              }}</span>
              <span v-else-if="item.status">
                <Badge
                  v-if="item.data.status === 'active'"
                  label="Active"
                  variant="primary"
                  background-color="rgba(19, 181, 106, 0.08)"
                  color="#13B56A"
                  style="
                    padding: 9px;
                    height: 33px;
                    font-size: 14px;
                    font-weight: 600;
                  "
                />
                <Badge
                  v-if="item.data.status === 'draft'"
                  label="Draft"
                  variant="primary"
                  background-color="rgba(135, 142, 153, 0.15)"
                  color="#333333"
                  style="
                    padding: 9px;
                    height: 33px;
                    font-size: 14px;
                    font-weight: 600;
                  "
                />
                <Badge
                  v-if="
                    item.data.status !== 'active' &&
                    item.data.status !== 'draft'
                  "
                  label="Closed"
                  variant="primary"
                  background-color="rgba(135, 142, 153, 0.15)"
                  color="#878E99"
                  style="
                    padding: 9px;
                    height: 33px;
                    font-size: 14px;
                    font-weight: 600;
                  "
                />
              </span>
              <span v-else-if="item.id">
                <Menu left top="-130" class="p-0" margin="65">
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 158px; height: 81px" class="py-3 px-2">
                    <div
                      class="flex py-1 px-3 more h-8 cursor-pointer"
                      @click="
                        $router.push({
                          name: 'performance Organisationl LongTerm Edit goal',
                          params: { id: item.data.id },
                        })
                      "
                    >
                      <icon
                        icon-name="edit"
                        class-name="text-blueCrayola mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">View & Edit</p>
                    </div>
                    <div
                      class="flex px-3 py-1 more h-8 cursor-pointer"
                      @click="deleteFunction(item.data.id)"
                    >
                      <icon
                        icon-name="delete"
                        class-name="text-desire mr-2"
                        size="xs"
                      />
                      <p class="pt-1" style="font-size: 14px">Delete</p>
                    </div>
                  </div>
                </Menu>
              </span>
            </template>
          </Table>
          <div v-else>
            <div class="w-full flex flex-col justify-center items-center mt-10">
              <icon icon-name="goals-empty" size="l" style="width: 300px" />
              <div class="w-1/2 text-base text-center">
                No organizational goals added. Start by adding one using the
                button below.
              </div>
              <div class="w-1/2 text-base text-center mt-6">
                <Button
                  class="text-flame"
                  @click="
                    $router.push({
                      name: 'performance Organisationl LongTerm New goal',
                    })
                  "
                >
                  <div class="flex items-center ml-6">
                    <icon icon-name="icon-plus" size="xsm" class="mr-3" />
                    Create New
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <ErrorComponent />
        </div>
      </div>

      <Modal v-if="dialogDel">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this organization goal?
            </p>
            <div class="flex">
              <Button class="text-white" @click="deleteGoal"
                :background="appearance.buttonColor || defaultBTNColor"
              >
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="dialogDel = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  components: {
    Table,
    Card,
    CardFooter,
    ErrorComponent,
    Badge,
    Button,
    Modal,
    Menu,
  },
  data() {
    return {
      PerfHeader: [
        { title: "Period", value: "period" },
        { title: "Description", value: "description" },
        { title: "KPAs", value: "kpaTypes" },
        { title: "Date Created", value: "createdAt" },
        { title: "Date Updated", value: "updatedAt" },
        { title: "Status", value: "status" },
        { title: "", value: "id", image: true },
      ],
      longTermGoals: [],
      loading: true,
      loadingTable: true,
      dialogDel: false,
      goalId: "",
      searchQuery: "",
      metaData: {},
      itemsPerPage: null,
      viewRegister: false,
      numberOfPage: null,
    };
  },
  computed:{
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    async createGoal() {
      try {
        await this.$handlePrivilege(
          "organisationalGoals",
          "createLongTermOrganisationalGoals"
        );
        this.$router.push({
          name: "performance Organisationl LongTerm New goal",
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `&${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOrganizationGoal(`?${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `search=${value}`;
      this.loadingTable = true;
      this.getOrganizationGoal(`?${this.searchQuery}`);
    },

    reload() {
      this.getOrganizationGoal("");
    },

    deleteFunction(id) {
      this.dialogDel = true;
      this.goalId = id;
    },

    deleteGoal() {
      this.$_deleteLongTermGoal(this.goalId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDel = false;
          this.loading = true;
          this.getOrganizationGoal();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    async getOrganizationGoal(params) {
      try {
        await this.$handlePrivilege(
          "organisationalGoals",
          "viewLongTermGoalsRegister"
        );
        this.viewRegister = true;
        this.longTermGoals = [];
        this.loadingTable = true;

        this.$_getLongTermGoal(params).then((response) => {
          const goalData = response.data.goals;
          this.metaData = response.data.meta;

          goalData.forEach((goals) => {
            this.longTermGoals.push({
              id: goals.goal.id,
              description: goals.goal.description
                ? goals.goal.description
                : "---",
              period: goals.goal.period,
              kpaTypes: goals.goal.long_term_kpaType,
              createdAt: goals.goal.createdAt,
              updatedAt: goals.goal.updatedAt,
              status: goals.goal.status,
            });
          });

          this.loading = false;
          this.loadingTable = false;
        });
      }
      catch (error) {
        this.viewRegister = false;
        this.loadingTable = false;
        this.loading = false;
      }
    },
  },

  mounted() {
      this.getOrganizationGoal("");
  },
};
</script>
